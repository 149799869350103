import React from 'react';
import style from './404.module.scss';
import Link from 'next/link';
import Head from 'next/head';

function NoMatch(props) {
  return (
    <div className={style.NoMatch}>
      <Head>
        <title>Page Not Found | Basler Services</title>
			</Head>
      <h1>404</h1>
      <h4>It looks like we couldn&#39;t find the page you were looking for. Don&#39;t worry though, we&#39;ll get you back on track!</h4>
      <div>
        <Link href={"/"}><a className={`${style.cta} ${style.primaryCta}`}>Home</a></Link>
        <Link href={"/e2-engineering-services"}><a className={`${style.cta} ${style.primaryCta}`}>E&#178; Engineering Services</a></Link>
        <Link href={"/segrity-products-and-services"}><a className={`${style.cta} ${style.primaryCta}`}>SEGRITY Products & Services</a></Link>
        <Link href={"/contact-us"}><a className={`${style.cta} ${style.primaryCta}`}>Contact Us</a></Link> 
      </div>
    </div>
  );
} 

export default NoMatch;